export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a  href='#page-top'>
           <img src='img/NikaLogo.png' className='navbar-brand page-scroll' alt='Nika'/>
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#features' className='page-scroll'>
                Features
              </a>
            </li>
            <li>
              <a href='#about' className='page-scroll'>
                Pricing
              </a>
            </li>
            {/*  <li>
              <a href='#services' className='page-scroll'>
                Services
              </a>
            </li> */}
            {/* <li>
              <a href='#portfolio' className='page-scroll'>
                Gallery
              </a>
            </li> */}
            {/*<li>
              <a href='#testimonials' className='page-scroll'>
                Testimonials
              </a>
            </li> */}
            <li>
              <a href='#demo' className='page-scroll'>
                Demo
              </a>
            </li>
      
            <li>
              <a href='#contact' className='page-scroll'>
                Contact
              </a>
            </li>
            <li>
              <a href='https://join.slack.com/t/nika-esg/shared_invite/zt-18ut3vys6-Z6hrxdkSz1C~7BPxdoOEAw' className='page-scroll' target="_blank"  rel="noreferrer" >
                Join our Slack
              </a>
            </li>
       
          </ul>
        </div>
      </div>
    </nav>
  )
}
