export const Contact = (props) => {

  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-7 col-md-offset-1 contact-info'>
          <h3>Contact Info</h3>
          <div className='contact-item'>      
              <p>
                If you are partner, VC or policy maker, email&nbsp; 
                <a href="mailto:mgmt@nika-esg.com" className='email-address'>{props.data ? props.data.emailManagement : 'loading'};</a>
              </p>
              <p>
              If you are interested in our product, email&nbsp;
                <a href="mailto:sales@nika-esg.com" className='email-address'>{props.data ? props.data.emailSales : 'loading'};</a>
              </p>
              <p>
              Otherwise, email&nbsp;
                <a href="mailto:info@nika-esg.com" className='email-address'>{props.data ? props.data.emailInfo : 'loading'}.</a>
              </p>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
          <h3>&nbsp;</h3>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2022 Nika ESG Pte. Ltd.
          </p>
        </div>
      </div>
    </div>
 
  )
}
