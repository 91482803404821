export const Features = (props) => {
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Features</h2>
          <p>A data infra where different teams can manage their own data to</p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
              
                <div key={`${d.title}-${i}`} className='m-b-20 '>
                  <div className='col-xs-12 col-md-12 m-b-20 '>
                    <div className='col-xs-12 col-md-8 p-r-20'>
                    <img src={d.imgURL} className='feature-img' alt={d.title} />
                    </div>
                    {/* <div className='col-xs-12 col-md-1'></div> */}
                    <div className='col-xs-12 col-md-4 m-b-20'>
                    {' '}

                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                    </div>
                  </div>
                  <hr class="feature"></hr>
                </div>
                    
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  )
}
