export const About = (props) => {
  return (
    <div id="about" className='text-center'>
      <div className="container">
      <div className='section-title'>
          <h2>Pricing</h2>
        </div>
        <div className="row">  
          <div className="col-xs-12 col-md-4 pricing-m-b">
            <div className='pricing-container'>
                <h3>Starter Tier</h3>
                <div className='pricing-feature-container'>
                <div className="price-number text-center">2000</div>
                <div className="text-center m-b-20">per month</div>
                <ul className="m-b-20 a">
                  <li>10 Editors</li>
                  <li>10 Members</li>
                </ul>
                <ul className="m-b-20 a">
                  <li>ESG glossary</li>
                  <li>Business intelligence</li>
                  <li>Data catalog</li>
                  <li>Emission Factor Manager</li>
                  <li>Granular access control</li>
                  <li>API-based lineage creation</li>
                </ul>
                </div>
                
          
           </div>
          </div>
          <div className="col-xs-12 col-md-4 pricing-m-b">
            <div className='pricing-container'>
            <h3>Premier Tier</h3>
            <div className='pricing-feature-container'>
            <div className="price-number text-center">5000</div>
                <div className="text-center m-b-20">per month</div>
                <ul className="m-b-20 a">
                  <li>20 Editors</li>
                  <li>20 Members</li>
                </ul>
                <div className='bold-text'>Get all Starter Tier features, plus:</div>
                <ul className="m-b-20 a">
                  <li>ESG glossary</li>
                  <li>PII classification</li>
                  <li>Geo-emission datastore</li>
                  <li>Integration with Slack/Lark</li>
                  <li>Lineage via SQL parsing</li>
                </ul>
                </div>

            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className='pricing-container'>
            <h3>Enterprise Tier</h3>
            <div className='pricing-feature-container'>
            <div className="price-number text-center">Custom</div>
                <div className="text-center m-b-20">Pricing</div>
                <ul className="m-b-20 a">
                  <li>Unlimited Editors</li>
                  <li>Unlimited Members</li>
                </ul>
                <div className='bold-text'>Custom features based on
your requirements</div>
                </div>

            </div>
    
          </div>
        </div>
      </div>
    </div>
  );
};
