import HubspotForm from 'react-hubspot-form'
export const Demo= (props) => {
  return (
    <div id='demo' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Submit Form to Access Demo</h2>
        </div>
        <div id='row'>
          <HubspotForm 
          portalId="21892524"
          formId= "343fc43e-50f1-43e2-b8fa-0b3e11d3b087"
          />
        </div>
      </div>
    </div>
  )
}
